import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/_reset.scss'
import '@/styles/global.scss'

import 'amfe-flexible/index'
Vue.config.productionTip = false
import { Toast ,Icon ,Popup } from 'vant'
Vue.use(Toast)
Vue.use(Icon)
Vue.use(Popup)
import Vconsole from 'vconsole'

if (process.env.VUE_APP_MODE == 'dev') {
  const vConsole = new Vconsole()
  Vue.use(vConsole)
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
